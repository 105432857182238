var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"seat-selection-box",class:{'can-select': !!_vm.setSeats || !!_vm.onClickSeat},style:({
            '--sw': _vm.size.width + 'px',
            '--sh': _vm.size.height + 'px',
        })},[_c('div',{staticClass:"padding-container"},[_c('div',{staticClass:"seating-plan-seats"},_vm._l((_vm.rows),function(row){return _c('div',{key:row.uuid,staticClass:"seating-plan-row",style:({
                        '--rw': row.width + 'px',
                        '--rh': row.height + 'px',
                        '--rx': row.x + 'px',
                        '--ry': row.y + 'px',
                    })},[_c('span',{staticClass:"row-label left"},[_vm._v(_vm._s(row.label))]),_vm._v(" "),_c('span',{staticClass:"row-label right"},[_vm._v(_vm._s(row.label))]),_vm._v(" "),_c('div',_vm._l((row.seats),function(seat){return _c('button',{key:seat.uuid,ref:_vm.isSelected(row, seat) ? 'selectedSeats' : undefined,refInFor:true,staticClass:"seat",class:{
                                space: seat.isSpace,
                                disabledPerson: _vm.isDisabledPersonSeat(seat),
                                selected: _vm.isSelected(row, seat),
                                highlighted: _vm.isHighlighted(row, seat) && !_vm.isSelected(row, seat),
                                hasHighlights: _vm.highlightSeats.length > 0 && !_vm.setSeats,
                                occupied: _vm.isOccupied(row, seat) && !_vm.isSelected(row, seat) && !_vm.isHighlighted(row, seat),
                            },style:({
                                '--w': seat.width + 'px',
                                '--h': seat.height + 'px',
                                '--x': seat.x + 'px',
                                '--y': seat.y + 'px',
                                '--color': _vm.getSeatColor(seat),
                            }),attrs:{"type":"button"},on:{"click":function($event){return _vm.onClick(row, seat)},"mouseover":function($event){return _vm.onHover(row, seat)}}},[_c('span',{staticClass:"nr"},[_vm._v(_vm._s(seat.label))]),_vm._v(" "),(_vm.isDisabledPersonSeat(seat))?_c('span',{staticClass:"icon disabled-person"}):_vm._e()])}),0)])}),0)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }