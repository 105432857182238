import { render, staticRenderFns } from "./STInputBox.vue?vue&type=template&id=11352099"
import script from "./STInputBox.vue?vue&type=script&lang=ts"
export * from "./STInputBox.vue?vue&type=script&lang=ts"
import style0 from "./STInputBox.vue?vue&type=style&index=0&id=11352099&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports